import { getWidthAndHeight } from '../ute/getWidthAndHeight';

const defaultContent = document.createElement('div');
defaultContent.innerHTML = '<p>&nbsp;</p>';

const defaults = {
  entry: 'bottom', // or left or right
  size: 'auto', // px in the direction of the drawer, or auto to fit content
  timeout: 0, // 0 = no timeout, or 'modal' = modal display, with no timeout
  type: 'drawer_new', // or dialog
  showClose: true,
  content: defaultContent,
  afterOpen: null, // runs after drawer has opened
  afterClose: null, // runs after drawer has closed
  drawerCssClass: null,  // css class to add to drawer
  setCloseOnUpdate: false // set to false to prevent closing on header click
}
const vBuffer = 50;
const hBuffer = 50;
const map = {
  'bottom': {
    'buffer': vBuffer,
    'dimension': 'height',
    'margin': 'margin-top'
  },
  'left': {
    'buffer': hBuffer,
    'dimension': 'width',
    'margin': 'margin-left'
  },
  'right': {
    'buffer': hBuffer,
    'dimension': 'width',
    'margin': 'margin-right'
  }
}

export default class nxDrawer {
  constructor(options) {
    this.opts = {...defaults, ...options};
    // get rid of any drawer or dialog if there already is one
    if(window.nx.drawer.activeDrawer) { window.nx.drawer.activeDrawer.close(); }
    if(Dialogs) { Dialogs.close(); }
    this.displayPageButtons(false);
    this.createDrawer();
    if (this.opts.timeout === 'modal') {this.createModalBg();}
    document.body.appendChild(this.drawer);
    this.dimensions = getWidthAndHeight(this.getContent());
    this.slideIn();
    if(this.opts.timeout !== 'modal' && this.opts.timeout != 0) {
      this.timer = window.setTimeout(this.close.bind(this), this.opts.timeout);
    }
    window.nx.drawer.activeDrawer = this;
    return this;
  }
  getContent() {
    if(typeof this.opts.content === 'string') {
      const div = document.createElement('div');
      div.classList.add('nx-drawer__content');
      div.innerHTML = this.opts.content;
      return div;
    }
    return this.opts.content;
  }
  createDrawer() {
    const drawer = this.drawer = document.createElement('div');
    const middle = this.middle = document.createElement('div');
    const inner = this.inner = document.createElement('div');
    drawer.appendChild(middle);
    middle.appendChild(inner);
    inner.appendChild(this.getContent());
    drawer.classList.add('nx-drawer');
    if (this.opts.drawerCssClass) {drawer.classList.add(this.opts.drawerCssClass); }
    drawer.classList.add(`nx-drawer-${this.opts.entry}`);
    middle.classList.add('nx-drawer__middle');
    middle.classList.add(`nx-drawer__middle-${this.opts.entry}`);
    inner.classList.add('nx-drawer__inner');
    if (this.opts.showClose) {this.createCloseButton();}
  }
  createCloseButton() {
    const closeButton = document.createElement('a');
    closeButton.classList.add('nx-drawer__close');
    closeButton.innerHTML = '&times;';
    closeButton.addEventListener('click', this.close.bind(this));
    this.drawer.appendChild(closeButton);
  }
  createModalBg() {
    const modalBg = document.createElement('div');
    modalBg.classList.add('nx-drawer__modal-bg');
    modalBg.addEventListener('click', this.close.bind(this));
    document.body.appendChild(modalBg);
    this.modalBg = modalBg;
  }
  updateContent(newContent, resize = true) {
    this.opts.content = newContent;
    this.inner.innerHTML = '';
    this.inner.insertBefore(this.getContent(), null);
    this.dimensions = getWidthAndHeight(this.getContent());
    if (this.opts.setCloseOnUpdate) {
      const closeButton = document.getElementsByClassName('nx-drawer__close')[0];
      if (closeButton) { closeButton.addEventListener('click', this.close.bind(this)) }
    }
    if (resize) {
      const m = map[this.opts.entry];
      this.slideIn();
    }
    //execute embedded js
    this.inner.querySelectorAll('script').forEach(scriptlet => {eval(scriptlet.innerHTML);}); // eslint-disable-line no-eval
  }
  slideIn() {
    let s;
    const m = map[this.opts.entry];
    if (this.opts.size === 'auto') {
      s = this.dimensions[m.dimension];
    } else {
      s = this.opts.size;
    }
    this.middle.style[m.dimension] = s + 'px';
    this.drawer.style[m.margin] = `-${s}px`;
    setTimeout(() => { // call any after-open function
      if (this.drawer) {
        this.drawer.style.transition = 'margin 0.5s';
        this.drawer.style[m.dimension] = s + 'px';
        this.drawer.style[m.margin] = 0;
        if (this.modalBg) this.modalBg.classList.add('nx-drawer__modal-bg-visible');
      }
      if(typeof this.opts.afterOpen === 'function') {
        this.opts.afterOpen(this);
        this.opts.afterOpen = null;
      }
    }, 0);
  }
  close() {
    if( !this.drawer ) {return; }
    const m = map[this.opts.entry];
    this.drawer.style[m.margin] = '-500px';
    if (this.modalBg) {this.modalBg.classList.remove('nx-drawer__modal-bg-visible');}
    if (this.timer) {window.clearTimeout(this.timer);}
    window.setTimeout(this.remove.bind(this), 500);
    this.displayPageButtons(true);
    if(typeof this.opts.afterClose === 'function') {
      this.opts.afterClose(this);
      this.opts.afterClose = null;
    }
  }
  remove() {
    if( this.drawer ) {
      this.drawer.remove();
      this.drawer = null;
    }
    if (this.modalBg) {this.modalBg.remove();}
  }
  displayPageButtons(showButtons) {
    const btto = document.getElementById('btto');
    const chat = document.querySelector('.genesys-app');
    if(showButtons) {
      if(btto) {btto.classList.remove('hide-for-now');}
      if(chat) {chat.style.display = '';}
    } else {
      if(btto) {btto.classList.add('hide-for-now');}
      if(chat) {chat.style.display = 'none';}
    }
  }
}
